<template>
  <div class="ring main">
    <div class="head">
      <div class="items">
        <div class="label">类型:</div>
        <div class="item-main">
          <div class="item it-min" :class="item.value == CategoryName ? 'sel':''" @click="changeItems(item.value)"
               v-for="(item, index) in type" :key="index">{{item.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <loading v-show="loadings == 10"></loading>

      <div class="items btn" v-show="loadings == 30" v-for="(item, index) in items" :key="index"
           @click="goDel(item.standardNumber)">
        <div class="anmi-imgs">
          <img :src="item.image" alt="">
          <img :src="item.image" class="imgss" alt="">
        </div>
        <div class="title">{{item.title}}</div>
        <div class="price">￥{{item.price}}</div>
      </div>
    </div>
    <div class="page last">
      <el-pagination background layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize"
                     :total="total" @current-change="changePage"></el-pagination>
    </div>
  </div>
</template>

<script>
  import loading from '@/components/loading/loading'
  import Ring from '@/api/ring.js'

  export default {
    name: 'search',
    data () {
      return {
        items: '',
        total: 500,
        currentPage: 1,
        pageSize: 40,
        // 类型
        CategoryName: '',
        // 类型
        type: '',
        // 材质
        material: ['18k', 'PT950'],
        // 商铺id
        relationId: '',
        loadings: 10
      }
    },
    mounted () {

    },
    created () {
      console.log(this.$route.query)
      // if (this.$route.query.type) {
      //   this.CategoryName = this.$route.query.type === '求婚钻戒' ? '女戒' : '对戒'
      // }
    },
    components: {
      loading
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationId != '' || newValue == '') return
          this.relationId = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.$getif(shop.vipType)
            this.getInit()
          }
        },
        immediate: true,
      },
      $route (to, from) {
        if (to.path != '/ringSearch') return
        if (!to.query.type) {
          this.CategoryName = ''
          return
        }
        let type = ''
        if (to.query.type === '求婚钻戒') {
          type = '女戒'
        }
        if (to.query.type === '结婚对戒') {
          type = '对戒'
        }
        if (type == this.CategoryName) return
        if (type != this.CategoryName) {
          let name = type
          this.CategoryName = name
          this.getItem()
        }
      }
    },
    methods: {
      changePage (val) {
        this.currentPage = val
        this.getItem()
      },
      changeItems (val) {
        if (this.CategoryName == val) {
          this.CategoryName = ''
        } else {
          this.CategoryName = val
        }
        this.getItem()
      },
      // 获取类型
      getInit () {
        Ring.init().then(res => {
          console.log(res)
          if (res.status == 200) {
            let data = res.result
            this.type = data
            this.getItem()
          }
        })
      },
      // 获取列表
      getItem () {
        this.loadings = 10
        let params = {
          RelationId: this.relationId,
          CategoryName: this.CategoryName,
          PageSize: this.pageSize,
          PageIndex: this.currentPage
        }
        this.items = []
        Ring.list(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            this.loadings = 30
            let data = res.result
            this.total = data.totalItems
            this.items = data.items
          } else {
            this.loadings = 20
          }
        }).catch(error => {
          this.loadings = 20
        })
      },
      goDel (id) {
        // this.$router.push({
        //   path: '/ringDetails',
        //   query: {
        //     relationId: this.relationId,
        //     standardNumber: id
        //   }
        // })
        window.open(`/ringDetails?relationId=${this.relationId}&standardNumber=${id}`)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/assets/css/main.scss';

  @mixin dis-last {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ring {
    padding: 40px 0 80px;

    .it-min:hover{
      color: #FF8178;
    }

    .head {
      width: 1200px;
      margin: 0 auto;
      padding: 0 0 20px;
      border-bottom: 1px solid #D9D9D9;

      .items {
        margin: 10px 0 20px;
        @include dis-last;
        color: #333333;
        font-size: 14px;

        .label {
          /*min-width: 50px;*/
        }

        .item-main {
          @include dis-last;

          .item {
            min-width: 80px;
            text-align: center;
            position: relative;
            cursor: pointer;
            padding: 0 15px;

            &.sel {
              text-decoration: underline;
            }

            &:first-child {
              min-width: 60px;
              text-align: left;
            }

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 5px;
              width: 2px;
              height: 10px;
              background: #CCCCCC;
            }

            &:first-child:before {
              background: #FFFFFF;
            }
          }
        }
      }
    }

    .list {
      width: 1225px;
      min-height: 800px;
      padding: 70px 0 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      margin: 0 auto;

      .items {
        width: 281px;
        height: 400px;
        margin-right: 25px;
        margin-bottom: 25px;

        &:hover {
          /*border-bottom: 6px solid #F5B5A9;*/
          animation: borbor .1s;
          animation-fill-mode: forwards;

          .anmi-imgs {
            /*.imgss{*/
            /*  animation: botbot .5s;*/
            /*  animation-fill-mode: forwards;*/
            /*}*/
            .imgss {
              top: 0;
              transition: all 0.4s;
            }
          }
        }

        .anmi-imgs {
          width: 281px;
          height: 281px;
          position: relative;
          overflow: hidden;

          img {
            width: 281px;
            height: 281px;
          }

          .imgss {
            position: absolute;
            transition: all 0.4s;
            top: -281px;
            left: 0;
            z-index: 1;
          }
        }

        .title {
          margin-top: 25px;
          font-size: 15px;
          color: #333333;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .price {
          font-size: 17px;
          color: #FF8178;
          font-weight: 600;
          margin-top: 10px;
        }
      }
    }

    .page {
      text-align: right;
    }
  }

  @keyframes borbor {
    from {
      border-bottom: 1px solid #F5B5A9;
    }
    to {
      border-bottom: 6px solid #F5B5A9;
    }
  }

  @keyframes botbot {
    from {
      transform: scale(1.0, 1.0);
      top: -281px;
    }
    to {
      transform: scale(1.3, 1.3);
      top: 0px;
    }
  }
</style>
