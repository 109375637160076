import {POST, GET} from './index'

// api/StyleLibrary/init 钻饰查询初始化
function init (params) {
  return GET('/api/StyleLibrary/init',params)
}

// api/StyleLibrary/list 获取钻饰列表
function list (params) {
  return GET('/api/StyleLibrary/list',params)
}

// api/StyleLibrary/detail 钻石详情
function detail (params) {
  return GET('/api/StyleLibrary/detail',params)
}


// api/StyleLibrary/stock 款式现货数据
function stock (params) {
  return GET('/api/StyleLibrary/stock',params)
}

// api/StyleLibrary/customized 获取款式定制
function customized (params) {
  return GET('/api/StyleLibrary/customized',params)
}

// api/StyleLibrary/RecommendList 首页推荐
function RecommendList (params) {
  return GET('/api/StyleLibrary/RecommendList',params)
}
export default {
  init,
  list,
  detail,
  stock,
  customized,
  RecommendList
}
